<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<locationsequenceheader-form-header :modelPk="modelPk"></locationsequenceheader-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-row class="pui-form-layout">
				<v-col cols="12">
					<pui-field-set :title="$t('form.locationsequenceheader.header')">
						<v-row dense>
							<v-col cols="4" v-if="!this.$attrs.parentpk && isCreatingElement">
								<pui-select
									:id="`portid-${modelName}`"
									:attach="`portname-${modelName}`"
									:label="this.$t('form.locationsequenceheader.port')"
									toplabel
									clearable
									required
									:disabled="!isCreatingElement"
									v-model="model"
									modelName="port"
									:itemsToSelect="[{ id: model.portid }]"
									:modelFormMapping="{ id: 'portid' }"
									:key="'portid_' + portidKey"
									itemValue="id"
									itemText="portname"
									reactive
									:fixedFilter="filterByUserPortsAndAuthority"
								></pui-select>
							</v-col>
							<v-col cols="4">
								<pui-select
									:id="`locationfrom-${modelName}`"
									:attach="`locationfrom-${modelName}`"
									:label="$t('form.locationsequenceheader.locationfrom')"
									toplabel
									clearable
									v-model="model"
									modelName="location"
									:disabled="formDisabled || !model.portid"
									:modelFormMapping="{ id: 'locationfrom' }"
									:itemsToSelect="[{ id: model.locationfrom }]"
									itemValue="id"
									itemText="description"
									:order="{ description: 'asc' }"
									:fixedFilter="filterLocation"
									:key="'visitid_' + portidKey"
									required
								></pui-select>
							</v-col>
							<v-col cols="4">
								<pui-select
									:id="`locationto-${modelName}`"
									:attach="`locationto-${modelName}`"
									:label="$t('form.locationsequenceheader.locationto')"
									toplabel
									clearable
									v-model="model"
									modelName="location"
									:disabled="formDisabled || !model.portid"
									:modelFormMapping="{ id: 'locationto' }"
									:itemsToSelect="[{ id: model.locationto }]"
									itemValue="id"
									itemText="description"
									:order="{ description: 'asc' }"
									:fixedFilter="filterLocation"
									:key="'locationid_' + portidKey"
									required
								></pui-select>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="4">
								<pui-text-field
									:id="`averagetime-${modelName}`"
									v-model="model.averagetime"
									:label="$t('form.locationsequenceheader.averagetime')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="10"
								></pui-text-field>
							</v-col>
							<v-col cols="4">
								<pui-select
									:id="`shiptype-${modelName}`"
									:attach="`shiptype-${modelName}`"
									:label="$t('form.locationsequenceheader.shiptype')"
									toplabel
									clearable
									v-model="model"
									modelName="shiptypepa"
									:disabled="formDisabled"
									:modelFormMapping="{ code: 'shiptype' }"
									:itemsToSelect="[{ code: model.shiptype }]"
									itemValue="code"
									itemText="description"
									:order="{ description: 'asc' }"
									:fixedFilter="filterByPortAuthorityUser"
								></pui-select>
							</v-col>
							<v-col cols="4" class="align-self-center">
								<pui-checkbox
									:id="`pilotage-locationsequenceheader`"
									:label="$t('form.locationsequenceheader.pilotage')"
									v-model="model.pilotage"
									:disabled="formDisabled"
								></pui-checkbox>
							</v-col>
						</v-row>
						<v-row dense>
							<v-col cols="4">
								<pui-select
									:id="`locationstartvisit-${modelName}`"
									:attach="`locationstartvisit-${modelName}`"
									:label="$t('form.locationsequenceheader.locationstartvisit')"
									toplabel
									clearable
									v-model="model"
									modelName="location"
									:disabled="formDisabled || !model.portid"
									:modelFormMapping="{ id: 'locationstartvisit' }"
									:itemsToSelect="[{ id: model.locationstartvisit }]"
									itemValue="id"
									itemText="description"
									:order="{ description: 'asc' }"
									:fixedFilter="filterLocation"
								></pui-select>
							</v-col>
						</v-row>
					</pui-field-set>
					<pui-field-set v-if="!isCreatingElement" :title="$t('form.locationsequenceheader.step')">
						<pui-master-detail
							componentName="locationsequencestep-grid"
							:parentModelName="modelName"
							:parentPk="pk"
							:parentPkChildFk="{ id: 'seqheaderid' }"
							:formDisabled="formDisabled"
							:modalDialog="isModalDialog"
							:parentModel="model"
						></pui-master-detail>
					</pui-field-set>
				</v-col>
			</v-row>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns :formDisabled="formDisabled" :saveDisabled="saving" :save="save" :back="back"></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import PuiGridDetailMixin from 'pui9-mixins/PuiGridDetailMixin';
import LocationsequenceheaderFormHeader from './LocationsequenceheaderFormHeader.vue';
import locationsequenceheaderActions from './LocationsequenceheaderActions';

export default {
	name: 'locationsequenceheader-form',
	mixins: [PuiFormMethodsMixin, PuiGridDetailMixin],
	components: { LocationsequenceheaderFormHeader },
	data() {
		return {
			modelName: 'locationsequenceheader',
			childModelName: 'locationsequencestep',
			portidKey: 0,
			portidFixedFilter: null,
			filterLocation: null,
			actions: locationsequenceheaderActions.formactions
		};
	},
	methods: {
		afterGetData() {
			if (this.isCreatingElement) {
				//Get berth data in berth detail
				if (this.$attrs.parentpk) {
					const opts = {
						model: 'berth',
						filter: {
							groupOp: 'and',
							groups: [],
							rules: [
								{
									field: 'id',
									op: 'eq',
									data: JSON.parse(atob(this.$attrs.parentpk)).id
								}
							]
						}
					};
					this.$puiRequests.postRequest('/puisearch', opts, (response) => {
						if (response.data && response.data.data[0]) {
							//Fill data
							this.model.portid = response.data.data[0].portid;
						} else {
							this.model.portid = null;
						}
					});
				} else {
					this.model.portid = null;
				}
				this.model.portauthorityid = this.$store.getters.getPortAuthorityId;
			}
		}
	},
	computed: {
		filterByUserPortsAndAuthority() {
			const userPorts = this.$store.getters.getPortsIds;
			return {
				groups: [],
				groupOp: 'and',
				rules: [
					{ field: 'id', op: 'in', data: userPorts },
					{ field: 'portauthorityid', op: 'eq', data: this.model.portauthorityid }
				]
			};
		},
		filterByParentPk() {
			let fk = this.model.id; //JSON.parse(atob(this.$attrs.pk)).id;
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'seq_header_id', op: 'eq', data: fk }]
			};
		},
		filterByPortUser() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'port_id', op: 'in', data: this.$store.getters.getPortsIds }]
			};
		},
		filterByPort() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'port_id', op: 'eq', data: this.model.portid }]
			};
		},
		filterByPortAuthorityUser() {
			return {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'port_authority_id', op: 'eq', data: this.$store.getters.getPortAuthorityId }]
			};
		}
	},
	created() {},
	watch: {
		'model.portid'(newVal) {
			if (!newVal) {
				this.model.portid = null;
				this.filterLocation = null;
			}
			this.filterLocation = this.model.portid ? this.filterByPort : this.filterByPortUser;
			this.portidKey += 1;
		}
	}
};
</script>
